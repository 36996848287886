<template>
  <div>
    <el-row>
      <!-- <el-col :span="21">
        <el-input size="mini" v-model="calcCoderName" disabled></el-input>
      </el-col>
      <el-col :span="3" class="coderButtons">
        <el-button-group class="coder-selector-buttons">
          <el-button icon="el-icon-search" size="mini" @click="searchCoders" :disabled="disabled"></el-button>
          <el-button icon="el-icon-circle-close" @click="selectCoder(null)" size="mini" :disabled="disabled">
          </el-button>
        </el-button-group>
      </el-col> -->
      <el-col :span="24">
        <el-input :disabled="disabled" size="mini" type="text" id="coderselin" v-model="calcCoderName" :readonly="true">
          <el-button slot="append" class="search" icon="el-icon-search" size="mini" @click="searchCoders" :disabled="disabled">
          </el-button>
          <el-button slot="append" class="clear" icon="el-icon-circle-close" @click="selectCoder({coderId: null})" size="mini" :disabled="disabled">
          </el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-dialog :id="getUniqueDialogId()" title="Select Coder" :visible.sync="showSearch" width="60%" :append-to-body="true" @opened="handleOpen">
      <!-- <el-tabs v-model="activeName"> -->
      <!-- <el-tab-pane label="Client Coders" name="client"> -->
      <el-popover trigger="click" placement="right" v-model="addCoderPopOverVisible">
        <el-form size="mini" ref="coderform" label-position="top" label-width="120px" :rules="coderRules" :model="coderForm">
          <el-form-item size="mini" label="Add Coder" prop="addCoder" id="addCoderInput">
            <el-input :disabled="disabled" size="mini" :maxlength="50" type="text" id="addCoder" v-model="coderForm.addCoder">
            </el-input>
          </el-form-item>
        </el-form>
        <br>
        <el-button-group style="margin: 0px 0px 0px 10px;">
          <el-button class="hiaButton" type="text" plain round @click="addCoderPopOverVisible = false; coderForm.addCoder = ''" size="mini">Cancel</el-button>
          <el-button class="hiaButton" plain round @click="addCoder()" size="mini">Confirm</el-button>
        </el-button-group>
        <div slot="reference" class="addButton">
          <el-button class="hiaButton" plain round size="mini" icon="el-icon-plus" title="Add Coder">Add New
            Coder
          </el-button>
        </div>
      </el-popover>
      <el-form size="mini" label-position="left" label-width="150px" v-loading="codersLoading" element-loading-spinner="atom-audit-loader">
        <v-server-table class="hiaTable" v-loading="tableLoading" @loading="tableLoading = true" element-loading-spinner="atom-audit-loader" @loaded="loaded" name="coderListGrid" ref="coderListGrid" id="coderListGrid" :columns="coderColumns" :options="coderoptions">
          <template slot="name" slot-scope="props">
            <el-button :disabled="props.row.inactive || disabled" tabindex="0" class="select-button" type="text" @click="selectCoder(props.row)" size="mini">
              {{props.row.name + (props.row.inactive ? ' (Inactive)': '')}}</el-button>
          </template>
          <template slot="h__email">
            Email <el-tooltip effect="dark" content="Linked user email address, users can be linked to coders via the User Admin menu." placement="top">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </template>
          <template slot="email" slot-scope="props">
            {{ props.row.users.length > 0 ? props.row.users.map(x => x.email).join(', ') : null }}
          </template>
        </v-server-table>
      </el-form>
      <!-- </el-tab-pane> -->
      <!-- </el-tabs> -->

    </el-dialog>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
    name: 'coderSelector',
    props: ['value', 'clientcoder', 'hiacoder', 'disabled'],
    data() {
      return {
        activeName: 'client',
        addCoderPopOverVisible: false,
        coderForm: {
          addCoder: ''
        },
        coderRules: {
          addCoder: [
            { required: true, message: 'Please Set Coder' }
          ]
        },
        localProvoiders: [],
        codersLoading: false,
        showSearch: false,
        tableLoading: false,
        coderColumns: ['name', 'email'],
        coderoptions: {
          requestFunction: async (data) => {
            try {
              const payload = {
                data: data
              }
              return await this.LOAD_CODER_PAGINATED(payload)
            } catch (err) {
              console.log(err)
            }
          },
          filterByColumn: true,
          headings: {
            email: 'User Email'
          },
          perPage: 15,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/coders/', ['LOAD_CODER_PAGINATED', 'POST_CODER']),
      getUniqueDialogId() {
        return `coderSelectorDialog${this._uid}`
      },
      loaded() {
        this.tableLoading = false
        const dialog = document.getElementById(this.getUniqueDialogId())
        const tds = dialog.getElementsByTagName('td')
        for (let i = 0; i < tds.length; i++) {
          tds[i].tabIndex = -1
        }
      },
      handleOpen() {
        const dialogId = this.getUniqueDialogId()
        const input = document.querySelector(`#${dialogId} #coderListGrid input[name="vf__name"]`)
        if (input) {
          input.focus()
        }
      },
      searchCoders() {
        this.showSearch = true
      },
      selectCoder(row) {
        // console.log(coderId)
        this.$emit('input', row.coderId)
        this.$emit('selected', row)
        this.showSearch = false
      },
      addCoder() {
        this.$refs.coderform.validate(async (valid) => {
          if (valid) {
            try {
              const payload = {
                coder: {
                  name: this.coderForm.addCoder
                }
              }
              const response = await this.POST_CODER(payload)
              this.addCoderPopOverVisible = false
              this.$refs.coderform.resetFields()
              this.selectCoder(response)
            } catch (err) {

            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      initialize() {
      }
    },
    computed: {
      calcCoderName: {
        get: function () {
          if (this.clientcoder) {
            return this.clientcoder.name
          }
          if (this.hiacoder) {
            return this.hiacoder.displayName
          }
          return null
        }// ,
        // set: function (val) {
        //   if (!val) {
        //     this.review.hiaCoderId = null
        //     this.review.coderId = null
        //   }

        //   if (typeof val === 'string') {
        //     this.review.hiaCoderId = val
        //     this.review.coderId = null
        //   } else {
        //     this.review.hiaCoderId = null
        //     this.review.coderId = val
        //   }
        // }
      }
    },
    created: function () {
      this.initialize()
    }
  }
</script>

<style scoped>
.coder-selector-buttons >>> .el-button.el-button--default.el-button--mini {
  padding: 4px 9px;
  margin-top: -1px;
}

.coderButtons {
  padding: 1px 0px 0px 5px;
}

.addButton {
  width: 115px;
  margin: 0px 0px 10px 0px;
}

>>> .select-button {
  border: 2px solid rgba(255, 255, 255, 0);
  padding: 7px 5px 7px 5px;
  width: 100%;
  text-align: left;
}

>>> .select-button:focus {
  border: 2px solid #409eff;
  border-radius: 0px;
}
</style>
