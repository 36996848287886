<!--
This is designed to be a reusable component to pick a Project ID.
-->

<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-input v-model="calcProjectName" placeholder="Review Project" :disabled="disabled" ref="projectName" readonly>
          <el-button slot="append" icon="el-icon-search" size="mini" @click="searchReviewProjects" :disabled="disabled"></el-button>
          <el-button v-if="clearable" slot="append" icon="el-icon-circle-close" @click="selectProject({id: null})" size="mini" :disabled="disabled">
          </el-button>
        </el-input>
      </el-col>
    </el-row>

    <el-dialog title="Select Project" :visible.sync="searchProjects" width="75%" :append-to-body="true">
      <div v-if="allowAdd">
        <el-button type="primary" size="mini" @click="toggleAddProject" icon="el-icon-plus">Add New Project</el-button>
      </div>
      <el-form size="mini" label-position="left" label-width="150px" v-loading="projectsLoading" element-loading-spinner="atom-audit-loader">
        <el-row>
          <el-col :span="24">
            <div key="1">
              <v-server-table class="hiaTable" v-loading="tableLoading" @loading="tableLoading = true" element-loading-spinner="atom-audit-loader" @loaded="tableLoading = false" ref="projectListGrid" id="projectListGrid" :data="localProjects" :columns="projectcolumns" :options="projectoptions">
                <template slot="startDate" slot-scope="props">
                  <div>
                    {{ props.row.startDate ? formatDate(props.row.startDate) : '' }}
                  </div>
                </template>
                <template slot="endDate" slot-scope="props">
                  <div>
                    {{ props.row.endDate ? formatDate(props.row.endDate) : '' }}
                  </div>
                </template>
                <template slot="projectID" slot-scope="props">
                  <el-button type="text" @click="selectProject(props.row)" size="mini">{{
                    props.row.projectID }}</el-button>
                </template>
              </v-server-table>
            </div>
          </el-col>
        </el-row>
      </el-form>

    </el-dialog>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
  import { createHelpers } from 'vuex-map-fields'

  import dateHelper from '@/mixins/date-helper'
  import hiaTable from '@/mixins/hiaTable'

  // needed to use vuex-map-fields with module
  const { mapFields } = createHelpers({
    getterType: 'projects/getField',
    mutationType: 'projects/updateField'
  })

  export default {
    name: 'projectSelector',
    mixins: [dateHelper, hiaTable],
    props: {
      value: {
        default: 0,
        type: Number
      },
      disabled: Boolean,
      clearable: {
        default: true,
        type: Boolean
      },
      allowAdd: {
        default: false,
        type: Boolean
      },
      defaultQuery: {
        default: () => {
          return null
        },
        type: Object
      },
      project: Object
    },
    data() {
      return {
        filterInput: '',
        activeTab: 'Client',
        projectID: '',
        searchProjects: false,
        projectSearchLoading: false,
        loading: false,
        tableLoading: false,
        localProjects: [],
        localProjectsBackup: [],
        localRecentProjects: [],
        projectcolumns: ['projectID', 'identifierId', 'startDate', 'endDate', 'projectTypeId'],
        projectoptions: {
          requestFunction: (data) => {
            return this.LOAD_PROJECTS_PAGINATED(data)
          },
          initFilters: this.defaultQuery,
          filterByColumn: true,
          hiddenColumns: ['projectTypeId'],
          listColumns: {
            identifierId: []
          },
          headings: {
            reviewID: function (h) {
              return 'Review ID'
            },
            id: function (h) {
              return 'Project ID'
            },
            identifierId: function (h) {
              return 'Identifier'
            },
            startDate: function (h) {
              return 'Start Date'
            },
            endDate: function (h) {
              return 'End Date'
            }
          },
          columnsClasses: {
            endDate: 'endDate',
            startDate: 'startDate'
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        },
        dropdownsNeeded: ['projects', 'projectIdentifiers', 'projectTypes']
      }
    },
    methods: {
      ...mapMutations('managedLists/projects/', ['SET_SHOW_ADD_PROJECT_FORM', 'ADD_PROJECT_TO_RECENTLIST']),
      ...mapActions('managedLists/projects/', ['LOAD_PROJECTS_LIST', 'LOAD_PROJECTS_PAGINATED']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS', 'CONVERT_DROPDOWN_COLUMNFILTERARRAY']),
      intialize() {
        this.loading = true
        Promise.all([this.GET_DROPDOWNS(this.dropdownsNeeded)]).then(response => {
          Promise.all([this.CONVERT_DROPDOWN_COLUMNFILTERARRAY('projectIdentifiers')]).then(response => {
            this.projectoptions.listColumns.identifierId = response[0]
            this.projectIDcomputed = this.value
            this.loading = false
          })
        })
      },
      handleInput() {
        this.$emit('input', this.projectID)
      },
      // handleDialogOpened() {
      //   if (this.defaultQuery) {
      //     this.$refs.projectListGrid.setFilter(this.defaultQuery)
      //   }
      // },
      searchReviewProjects() {
        this.searchProjects = true
        // this.$nextTick(() => {
        //   if (this.defaultQuery) {
        //     this.$refs.projectListGrid.setFilter(this.defaultQuery)
        //   }
        // })
      },
      selectProject(row) {
        this.$emit('input', row.id)
        this.$emit('selected', row)
        this.searchProjects = false
      },
      toggleAddProject() {
        this.SET_SHOW_ADD_PROJECT_FORM(true)
      }
    },
    computed: {
      ...mapState('managedLists/projects/', ['projects', 'projectsLoading', 'showAddProjectForm', 'newProjectID', 'recentProjects']),
      ...mapFields(['showAddProjectForm']),
      ...mapGetters('managedLists/projects/', ['GET_PROJECT_IDENTIFIER']),
      calcProjectName: function () {
        if (this.project) {
          return this.project.projectID
        }
        return null
      }
    },
    created: function () {
      this.intialize()
    },
    mounted: function () {
      // this.intialize()
    },
    components: {
      // addProject
    },
    watch: {
      defaultQuery(val) {
        this.projectoptions.initFilters = val
      }
    }
  }

</script>

<style scoped>
.project-selector-buttons >>> .el-button.el-button--default.el-button--mini {
  padding: 4px 9px;
  margin-top: -1px;
}

>>> .endDate {
  width: 150px;
}

>>> .startDate {
  width: 150px;
}
</style>
