<template>
  <div>
    <el-row>
      <!-- <el-col :span="18">
        <el-input size="mini" class="providerInput" v-model="calcProviderName" disabled></el-input>
      </el-col>
      <el-col :span="6" class="coderButtons">
        <el-button-group class="project-selector-buttons">
          <el-button class="search" icon="el-icon-search" size="mini" @click="searchProviders" :disabled="disabled">
          </el-button>
          <el-button class="clear" icon="el-icon-circle-close" @click="selectProvider(null)" size="mini"
            :disabled="disabled">
          </el-button>
        </el-button-group>
      </el-col> -->
      <el-col :span="24">
        <el-input :disabled="disabled" size="mini" type="text" id="provSelinp" v-model="calcProviderName" :readonly="true">
          <el-button slot="append" class="search" icon="el-icon-search" size="mini" @click="searchProviders" :disabled="disabled">
          </el-button>
          <el-button slot="append" class="clear" icon="el-icon-circle-close" @click="selectProvider({providerId: null})" size="mini" :disabled="disabled">
          </el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-dialog :id="getUniqueDialogId()" title="Select Provider" :visible.sync="showSearch" width="60%" :append-to-body="true" @opened="handleOpen">
      <el-popover trigger="click" placement="right" v-model="addProviderPopOverVisible">
        <el-form size="mini" ref="providerform" label-position="top" label-width="120px" :rules="providerRules" :model="providerForm">
          <el-form-item size="mini" label="Add Provider" prop="addProvider" id="addProviderInput">
            <el-input :disabled="disabled" size="mini" :maxlength="50" type="text" id="addProvider" v-model="providerForm.addProvider">
            </el-input>
          </el-form-item>
        </el-form>
        <br>
        <el-button-group style="margin: 0px 0px 0px 10px">
          <el-button type="text" @click="addProviderPopOverVisible = false; providerForm.addProvider = ''" size="mini" class="hiaButton" plain round>
            Cancel</el-button>
          <el-button class="hiaButton" plain round @click="addProvider()" size="mini">Confirm</el-button>
        </el-button-group>
        <div slot="reference" class="addButton">
          <el-button class="hiaButton" plain round size="mini" icon="el-icon-plus" title="Add Provider">Add New
            Provider
          </el-button>
        </div>
      </el-popover>
      <el-form size="mini" label-position="left" label-width="150px" v-loading="providersLoading" element-loading-spinner="atom-audit-loader">
        <v-server-table class="hiaTable" v-loading="tableLoading" @loading="tableLoading = true" element-loading-spinner="atom-audit-loader" @loaded="loaded" name="providerListGrid" ref="providerListGrid" id="providerListGrid" :columns="providerColumns" :options="provideroptions" :data="localProvoiders">
          <template slot="name" slot-scope="props">
            <el-button tabindex="0" class="select-button" type="text" :disabled="props.row.inactive" @click="selectProvider(props.row)" size="mini">
              {{props.row.name + (props.row.inactive ? ' (Inactive)': '')}}</el-button>
          </template>
        </v-server-table>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import { mapActions, mapMutations } from 'vuex'
  export default {
    name: 'providerSelector',
    props: {
      value: Number,
      isNew: Boolean,
      provider: Object,
      disabled: Boolean,
      practiceNumber: Number
    },
    data() {
      return {
        addProviderPopOverVisible: false,
        providerForm: {
          addProvider: ''
        },
        providerRules: {
          addProvider: [
            { required: true, message: 'Please Set Provider' }
          ]
        },
        localProvoiders: [],
        providersLoading: false,
        showSearch: false,
        tableLoading: false,
        alreadyOpened: false,
        providerColumns: ['name'],
        provideroptions: {
          requestFunction: async (data) => {
            try {
              const payload = {
                data: data,
                practiceNumber: this.practiceNumber
              }
              return this.practiceNumber ? await this.LOAD_PRO_PROVIDER_PAGINATED(payload) : await this.LOAD_PROVIDER_PAGINATED(payload)
            } catch (err) {

            }
          },
          filterByColumn: true,
          headings: {
            name: 'Provider Name'
          },
          perPage: 15,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        },
        providerIndex: -1
      }
    },
    methods: {
      ...mapMutations('dropdowns/', ['PUSH_NEWPROVIDER']),
      ...mapActions('managedLists/providers/', ['LOAD_PROVIDER_PAGINATED', 'POST_PROVIDER_NEW', 'LOAD_PRO_PROVIDER_PAGINATED']),
      getUniqueDialogId() {
        return `providerSelectorDialog${this._uid}`
      },
      handleOpen() {
        const dialogId = this.getUniqueDialogId()
        const input = document.querySelector(`#${dialogId} #providerListGrid input[name="vf__name"]`)
        if (input) {
          input.focus()
        }
      },
      loaded() {
        this.tableLoading = false
        const dialog = document.getElementById(this.getUniqueDialogId())
        const tds = dialog.getElementsByTagName('td')
        for (let i = 0; i < tds.length; i++) {
          tds[i].tabIndex = -1
        }
      },
      searchProviders() {
        this.showSearch = true
        if (this.alreadyOpened) {
          this.$refs.providerListGrid.refresh()
        }
        this.alreadyOpened = true
      },
      selectProvider(row) {
        const providerId = row.providerId
        this.$emit('input', providerId)
        this.$emit('selected', row)
        this.showSearch = false
        if (this.isNew && this.$refs.providerListGrid) {
          this.providerIndex = this.$refs.providerListGrid.data.findIndex(f => f.providerId === providerId)
        }
      },
      addProvider() {
        this.$refs.providerform.validate(async (valid) => {
          if (valid) {
            try {
              const payload = {
                provider: {
                  name: this.providerForm.addProvider
                },
                practiceId: this.practiceNumber
              }
              const response = await this.POST_PROVIDER_NEW(payload)
              if (this.isNew) {
                this.$refs.providerListGrid.data.push(response)
              }
              this.addProviderPopOverVisible = false
              this.$refs.providerform.resetFields()
              if (this.practiceNumber) {
                this.PUSH_NEWPROVIDER({ providerId: response.providerId, name: payload.name, practiceId: payload.practiceId })
              }
              this.selectProvider(response)
            } catch (err) {

            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    computed: {
      calcProviderName: {
        get: function () {
          if (this.provider) {
            return this.provider.name
          } else if (this.isNew) {
            // if (!this.value) {
            //   this.providerIndex = -1
            //   return null
            // }
            // if (this.providerIndex !== -1) {
            //   return this.$refs.providerListGrid.data[this.providerIndex] ? this.$refs.providerListGrid.data[this.providerIndex].name : null
            // }
            // return null
          }
          return null
        }// ,
        // set: function (val) {
        //   if (!val) {
        //     this.review.hiaCoderId = null
        //     this.review.coderId = null
        //   }

        //   if (typeof val === 'string') {
        //     this.review.hiaCoderId = val
        //     this.review.coderId = null
        //   } else {
        //     this.review.hiaCoderId = null
        //     this.review.coderId = val
        //   }
        // }
      }
    }
  }
</script>

<style scoped>
.project-selector-buttons >>> .el-button.el-button--default.el-button--mini {
  padding: 4px 9px;
  margin-top: -1px;
}

.coderButtons {
  padding: 1px 0px 0px 5px;
}

.addButton {
  width: 115px;
  margin: 0px 0px 10px 0px;
}

>>> .select-button {
  border: 2px solid rgba(255, 255, 255, 0);
  padding: 7px 5px 7px 5px;
  width: 100%;
  text-align: left;
}

>>> .select-button:focus {
  border: 2px solid #409eff;
  border-radius: 0px;
}
</style>
